import React, { useEffect, useState } from 'react';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
import axios from 'axios';
import ScreenConnected from './ScreenConnected';
import io from 'socket.io-client';
import './QRCode.css';
import trillBoardLogo from '../Trillboard Logo.png';
import videoImage from '../Group 1686555205.png';
import { QRCode } from 'react-qrcode-logo';

const QRcode = () => {
    const [myId, setmyId] = useState('');
    const [connected, setconnected] = useState(false);
    const [myFingerPrint, setmyFingerPrint] = useState('');
    const [loader, setloader] = useState(false);
    const [locationAllowed, setlocationAllowed] = useState(null);
    const [lat, setlat] = useState(null);
    const [long, setlong] = useState(null);
    const [address, setaddress] = useState('');
    const [mysocket, setmysocket] = useState(null);
    const [permission, setpermission] = useState('');

    useEffect(() => {
        const fpPromise = import('https://fpjscdn.net/v3/BwcpDekjRdgI3jp0m9EM')
            .then(FingerprintJS => FingerprintJS.load());

        // Get the visitor identifier when you need it.
        fpPromise
            .then(fp => fp.get())
            .then(result => {
                console.log("result", result.visitorId);
                setmyFingerPrint(result?.visitorId);
            })
            .catch(err => {
                console.log("error fingerPrint", err);
            });
    }, []);

    useEffect(() => {
        console.log("myfingerPrint", myFingerPrint);
    }, [myFingerPrint]);

    useEffect(() => {
        const checkLocationPermission = async () => {
            try {
                const permissionResult = await navigator.permissions.query({
                    name: 'geolocation'
                });

                if (permissionResult.state === 'granted') {
                } else if (permissionResult.state === 'prompt') {
                } else {
                    setpermission('denied');
                }
            } catch (error) {
                console.error('Error checking location permission:', error);
            }
        };

        checkLocationPermission();
    }, []);

    const fetchAddress = async (lat, long) => {
        console.log("lat", lat);
        console.log("long", long);
        const apiKey = 'AIzaSyAweGomkoxlfd4fYorpX1iXIfwz58LAyk8';
        const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${apiKey}`;
        fetch(geocodingApiUrl)
            .then((response) => response.json())
            .then((data) => {
                if (data.results && data.results.length > 0) {
                    setaddress(data.results[0].formatted_address);
                    console.log("address data", data.results[0].formatted_address);
                    setlocationAllowed(true);
                } else {
                    console.log("address data length less", data);
                }
            })
            .catch((error) => {
                console.error('Error fetching address:', error);
            });
    };

    const success = (pos) => {
        console.log(pos);
        const { latitude, longitude } = pos.coords;
        setlat(latitude);
        setlong(longitude);
        fetchAddress(latitude, longitude);
    };

    const errors = (err) => {
        setlocationAllowed(false);
        console.log("err", err);
    };

    const options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 1000000,
    };

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, errors, options);
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }, []);

    const getScreenDiagonalPixels = (widthPixels, heightPixels) => {
        return Math.sqrt(Math.pow(widthPixels, 2) + Math.pow(heightPixels, 2));
    };

    const diagonalSizeInInches = 15.6; // Example diagonal size in inches

    const dpi=window.devicePixelRatio * 96;


    


    useEffect(() => {
        if (((locationAllowed === true) || (locationAllowed === false)) && myFingerPrint) {
            if (locationAllowed) {
                console.log("true");
            }
            if (!locationAllowed) {
                console.log("false");
            }
            setloader(true);
            const getdata = async () => {
                await axios.get(`${process.env.REACT_APP_APP_URL}v2/earner/check-screen/${myFingerPrint}`, {
                    headers: {
                        "Content-Type": 'application/json'
                    }
                })
                    .then((res) => {
                        console.log("res status", res?.data?.status);
                        if (res?.data?.status === false) {
                            const socket = io(`https://chat.trillboards.com?fingerprint=${myFingerPrint}`);
                            setmysocket(socket);

                            socket.on('connect', () => {
                                console.log('WebSocket connected', socket.id);

                                const addScreen = async () => {
                                    const widthPx = window.innerWidth * window.devicePixelRatio;
                                    const heightPx = window.innerHeight*window.devicePixelRatio;

                                    // alert(`Dimensions pixels width ${window.screen.availWidth * window.devicePixelRatio} height ${window.outerHeight}`);
                                    // alert(`System details ${navigator.userAgent}`);

                                    // alert(`dimensions check width${widthPx} height${heightPx} pixelRatio ${window.devicePixelRatio}`);
                        
                                  const formdata = new FormData();
                                  formdata.append('dimension_width', widthPx);
                                  formdata.append('dimension_height', heightPx);
                                  formdata.append('fingerPrint', myFingerPrint);
                                  formdata.append('ppi',dpi);
                                  formdata.append('socket_id', socket.id);
                                  formdata.append('location', address);
                                  formdata.append('lat', Number(lat));
                                  formdata.append('long', Number(long));
                                  
                                  await axios.post(`${process.env.REACT_APP_APP_URL}v2/earner/attach-screen`, formdata, {
                                      headers: {
                                          "Content-Type": 'application/json'
                                      }
                                  })
                                  .then((res) => {
                                      console.log("res", res?.data?.data?._id);
                                      if (res?.data?.status === true) {
                                          setmyId(res?.data?.data?._id);
                                          console.log("in check");
                                          setloader(false);
                                      }
                                  })
                                  .catch((err) => {
                                      console.log("err", err);
                                  });
                              };
                              
                                addScreen();
                            });

                            socket.on('disconnect', () => {
                                console.log('WebSocket disconnected');
                            });

                            socket.emit('newMessage', JSON.stringify({ name: "John" }));

                            socket.on('receiveMessage', (data) => {
                                console.log('Received message from server:', data);
                            });
                            socket.on('chatmessage', (data) => {
                                console.log('Received message from server:', data);
                            });

                            socket.on('privateMessage', (data) => {
                                console.log("message from socket", data);
                                if (JSON.parse(data)?.fetchAdd === true) {
                                    window.location.reload();
                                }
                            });
                            return () => {
                                socket.disconnect();
                            };
                        }
                        else if (res?.data?.status === true) {
                            setmyId(res?.data?.data?._id);
                            setconnected(true);
                            setloader(false);
                        }
                    })
                    .catch((err) => {
                        console.log("err check-screen", err);
                    });
            };
            getdata();
            console.log("window.innerHeight", window.innerHeight, window.innerWidth);
        }
    }, [locationAllowed, permission, myFingerPrint]);

    return (
        <>
            {!connected ?
                <>
                    {(loader || locationAllowed === null) ? <div id="loader-overlay">
                        <div className="loader"></div>
                    </div>
                        :
                        <>
                            <section className="trillboard_wrap">
                                <div className="blue_boxwrap">
                                    <div className="trillboard_image">
                                        <img src={trillBoardLogo} alt="" />
                                    </div>
                                </div>
                            </section>
                            <section className="trillboard_scanboard sucess_connect" >
                                <div className="scanorcode_wrap">
                                    <QRCode
                                        size={200}
                                        style={{ height: "auto", maxWidth: "50%", width: "50%" }}
                                        value={`${myFingerPrint}?location=${locationAllowed}`}
                                        logoImage={trillBoardLogo}
                                        logoPadding={1}
                                        logoHeight={40}
                                        logoWidth={40}
                                    />
                                    <h2>Scan to Connect Screen</h2>
                                </div>
                                <div className="trillboard_videeo_list">
                                    <div className="trillboard_list">
                                        <p>1. Open Trill boards on your Phone</p>
                                        <p>2. Tap menu on right of Homepage </p>
                                        <p>3. Capture the QR code from the Screen</p>
                                        <p>4. Advertise</p>
                                    </div>
                                    <div className="trillboard_video_image">
                                        <img src={videoImage} alt="" />
                                    </div>
                                </div>
                            </section>
                        </>}
                </>
                : <ScreenConnected myFingerPrint={myFingerPrint} myId={myId} socket={mysocket} />}
        </>
    );
}

export default QRcode;
